<template>
    <div>
        <card-header title="Question Responses" icon="fa-clipboard-list-check"/>

        <card-toolbar>
            <button @click="$openCard('new-response', {registrationId: props.registrationId, tripId: registration.trip_id}, card)"><i class="fas fa-plus mr-2"/>New Response</button>
        </card-toolbar>

        <card-body>
            <card-list>

                <template v-for="g in responseGroups">
                    <subheader-list-item :title="g[0].user.last_name + ', ' + g[0].user.first_name" icon="fa-user"/>
                    <response-list-item
                        :card="card"
                        :response="r"
                        v-for="r in g"
                        :active="(child && child.definition.component === 'edit-response') && child.props.responseId === r.id.toString()"
                        @click="$openCard('edit-response', {registrationId: props.registrationId, responseId: r.id}, card)"
                    />
                </template>


            </card-list>
        </card-body>
    </div>
</template>

<script>
    import {client as http} from '../../http_client';

    import CardHeader from '../../TIER/components/CardHeader';
    import CardToolbar from '../../TIER/components/CardToolbar';
    import CardBody from '../../TIER/components/CardBody';
    import CardList from '../../TIER/components/CardList';
    import ResponseListItem from "@/components/ResponseListItem";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";

    export default {
        props: ['card', 'child', 'props'],
        components: {SubheaderListItem, ResponseListItem, CardBody, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                responses: [
                    {user: null}
                ],
                responseGroups: {},
                registration: {}
            }
        },
        methods: {
            loadAddons: function(force) {
                this.$emit('loading', true);
                http.get('/api/registrations/' + this.props.registrationId + '/responses', {force}).then(response => {
                    this.responses = response.data;

                    this.responseGroups = {}
                    for (let i = 0; i < this.responses.length; i++) {
                        const f = this.responses[i];
                        if (!this.responseGroups[f.user_id]) this.responseGroups[f.user_id] = [];
                        this.responseGroups[f.user_id].push(f);
                    }

                    for (const [key, value] of Object.entries(this.responseGroups)) {
                        value.sort(function(a,b) { return (a.sort > b.sort) ? 1 : -1 });
                        value.reverse();
                    }

                    http.get('/api/registrations/' + this.props.registrationId, {force}).then(response => {
                        this.registration = response.data;
                        this.$emit('loading', false);
                    });
                });

            },
            reload: function() {
                this.loadAddons(true);
            }
        },
        mounted() {
            this.loadAddons();
        }
    };
</script>
